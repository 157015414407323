import Trade from '../Trade';
import {
  isQuantBook,
  isOnShoreFund,
  isMacroBook,
  isQuantStrategyBook,
  isMacroStrategyBook
} from 'common/utils/DomainUtils';
import { getCurrentWorkingDay } from 'common/utils/DateUtils';
import TickerUtils from 'common/utils/TickerUtils';

import _ from 'lodash';
import moment from 'moment';
const REG_NUM_SPACE = /[\s\d]+/g;

const TXN_LIMIT_TYPES = {
  MIN_OPEN_TXN_QTY: 'MIN_OPEN_TXN_QTY',
  CN_FUT_POS_LMT: 'CN_FUT_POS_LMT',
  CN_FUT_OPEN_TXN_LMT: 'CN_FUT_OPEN_TXN_LMT'
};

const containsTimeRange = str => {
  if (!str) return false;
  const regex = /(\d{1,2}):(\d{2})-(\d{1,2}):(\d{2})/;
  return regex.test(str);
};

const _crossTradeRule = {
  fn: (v, fields, ctx) => {
    if (!ctx || Trade.isFXTrade(ctx.txnClass)) return false;
    if (!['SELL', 'COVR'].includes(fields.side)) return false;

    const { trades } = ctx;
    const posKey = Trade.parsePosKey(fields);

    // 1. must take the other trades into account.
    // 2. must minus prevFilledQuantity if edit GTC order.
    const aggTradeSignedDayQty = _.sum(
      trades
        .filter(t => Trade.parsePosKey(t) === posKey && t.side === fields.side)
        .map(t => Trade.parseSignedDayQty(t))
    );

    return (
      Math.abs(aggTradeSignedDayQty) > Math.abs(ctx.theoreticalPosnForClose) ||
      Math.sign(ctx.theoreticalPosnForClose) *
        Math.sign(aggTradeSignedDayQty) >=
        0
    );
  },
  msgFn: (_t, ctx) =>
    `Qty should be less or equal to position: ${Math.abs(
      ctx.theoreticalPosnForClose
    )}`,
  isWarningFn: (t, ctx) =>
    t.bookCode === 'T22' || ['Trader'].includes(_.get(ctx, 'user.role'))
};

const _limitsRule = {
  fn: (v, fields, ctx) => {
    if (!ctx || Trade.isFXTrade(ctx.txnClass)) return false;

    const { limits, positions, trades } = ctx;
    if (_.isEmpty(limits)) return false;
    const { fundCode, side } = fields;

    const limit = _.find(_.toPairs(limits), ([k, limitVal]) => {
      if (k === TXN_LIMIT_TYPES.MIN_OPEN_TXN_QTY) {
        if (['SELL', 'COVR'].includes(side)) return false;
        return v < limitVal;
      }

      if (k === TXN_LIMIT_TYPES.CN_FUT_POS_LMT) {
        if (!isOnShoreFund(fundCode)) return false;
        if (['SELL', 'COVR'].includes(side)) return false;

        const aggTradeSignedDayQty = _.sum(
          trades
            .filter(
              t =>
                t.fundCode === 'PLATCNY' &&
                t.ticker === fields.ticker &&
                t.side === fields.side
            )
            .map(t => Trade.parseSignedDayQty(t))
        );

        const targetAbsPos = Math.abs(
          _.sumBy(
            Object.values(positions).filter(
              p =>
                p.fundCode === 'PLATCNY' &&
                (side === 'BUY'
                  ? p.theoreticalPosnForOpen > 0
                  : p.theoreticalPosnForOpen < 0)
            ),
            p => p.theoreticalPosnForOpen
          ) + aggTradeSignedDayQty
        );
        return targetAbsPos > _.toInteger(limitVal);
      }

      if (k === TXN_LIMIT_TYPES.CN_FUT_OPEN_TXN_LMT) {
        if (!isOnShoreFund(fundCode)) return false;
        if (['SELL', 'COVR'].includes(side)) return false;

        const aggTradeAbsDayOpenQty = _.sum(
          trades
            .filter(
              t =>
                t.fundCode === 'PLATCNY' &&
                t.ticker === fields.ticker &&
                Trade.isOpen(t)
            )
            .map(t => Math.abs(Trade.parseSignedDayQty(t)))
        );

        const aggAbsOpenQty =
          _.sumBy(
            Object.values(positions).filter(p => p.fundCode === 'PLATCNY'),
            p => p.aggAbsOpenQty
          ) + aggTradeAbsDayOpenQty;

        return aggAbsOpenQty > _.toInteger(limitVal);
      }

      return false;
    });

    ctx.violatedLimit = limit;
    return !_.isNil(limit);
  },
  msgFn: (_t, ctx) =>
    `Order qty [${ctx.ticker}] is violated against limit : ${
      ctx.violatedLimit[0]
    } -> ${ctx.violatedLimit[1]}`,
  isWarningFn: (t, ctx) =>
    ['Trader', 'Admin', 'RiskMngr'].includes(_.get(ctx, 'user.role'))
};

const _minTradeExposureRule = {
  fn: (v, fields, ctx) =>
    ctx &&
    !Trade.isFXTrade(ctx.txnClass) &&
    !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
    ctx.usdNav &&
    ['BUY', 'SHRT'].includes(fields.side) &&
    Math.abs(v * ctx.usdValuePerShare) / ctx.usdNav < 0.00001,
  msgFn: (t, ctx) =>
    _.isNil(ctx.price) && t.orderType === 'MKT'
      ? 'Must use LMT order since price of security is not available.'
      : 'Qty is invalid since the trading value will be less than 0.001%.',
  isWarningFn: (t, ctx) => ['Trader'].includes(_.get(ctx, 'user.role'))
};

const _maxExposureRule = {
  fn: (v, fields, ctx) =>
    ctx &&
    !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
    !(isMacroBook(ctx.bookCode) || isMacroStrategyBook(ctx.bookStrategy)) &&
    !Trade.isFXTrade(ctx.txnClass) &&
    ctx.usdNav &&
    !(
      ctx.ticker.toUpperCase().includes('INDEX') &&
      ['SHRT'].includes(fields.side)
    ) &&
    ['BUY', 'SHRT'].includes(fields.side) &&
    Math.abs(
      (ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) *
        ctx.usdValuePerShare
    ) /
      ctx.usdNav >
      (['PCF', 'COP', 'ZJNF'].includes(ctx.fundCode) ? 0.2 : 0.075),
  msgFn: (t, ctx) =>
    `Qty is invalid since the position exposure will be greater than ${
      ['PCF', 'COP', 'ZJNF'].includes(ctx.fundCode) ? 20 : 7.5
    }%.`,
  isWarningFn: (t, ctx) => ['Trader'].includes(_.get(ctx, 'user.role'))
};

const _indexFutMaxExpRule = {
  fn: (v, fields, ctx) =>
    ctx &&
    !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
    !Trade.isFXTrade(ctx.txnClass) &&
    ctx.usdNav &&
    ctx.ticker.toUpperCase().includes('INDEX') &&
    ['SHRT'].includes(fields.side) &&
    Math.abs(
      (ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) *
        ctx.usdValuePerShare
    ) /
      ctx.usdNav >
      0.45,
  msgFn: () =>
    'Qty is invalid since the index short position exposure will be greater than 45%.',
  isWarningFn: (t, ctx) => ['Trader'].includes(_.get(ctx, 'user.role'))
};

const _liquidityHardRule = {
  fn: (v, fields, ctx) => {
    const liquidity =
      Math.abs(ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) /
      ctx.avgVolume;
    const result =
      ctx &&
      !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
      !Trade.isFXTrade(ctx.txnClass) &&
      ctx.avgVolume &&
      ctx.usdNav &&
      ['BUY', 'SHRT'].includes(fields.side) &&
      ((liquidity > 2 &&
        Math.abs(
          (ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) *
            ctx.usdValuePerShare
        ) /
          ctx.usdNav >
          0.03) ||
        liquidity > 5);
    ctx.liquidity = liquidity;
    return result;
  },
  msgFn: (t, ctx) =>
    ctx.liquidity > 5
      ? 'Qty is invalid since the pos liquidity > 5 days.'
      : 'Qty is invalid since the pos liquidity > 2 days and mv% > 3%.',
  isWarningFn: (t, ctx) =>
    ['Trader', 'Admin', 'RiskMngr'].includes(_.get(ctx, 'user.role'))
};

const _liquiditySoftRule = {
  fn: (v, fields, ctx) => {
    const liquidity =
      Math.abs(ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) /
      ctx.avgVolume;
    return (
      ctx &&
      !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
      !Trade.isFXTrade(ctx.txnClass) &&
      ctx.avgVolume &&
      ctx.usdNav &&
      ['BUY', 'SHRT'].includes(fields.side) &&
      liquidity > 2 &&
      liquidity <= 5 &&
      Math.abs(
        (ctx.aggTheoreticalPosn + Trade.parseSignedDayQty(fields)) *
          ctx.usdValuePerShare
      ) /
        ctx.usdNav <=
        0.02
    );
  },
  msgFn: () => 'Qty is invalid since the pos liquidity > 2 days.',
  isWarningFn: () => true
};

// const _mixedTradeRule = {
//   fn: (v, fields, ctx) =>
//     ctx &&
//     ctx.tradeQty !== 0 &&
//     Math.sign(ctx.tradeQty) !== (['SELL', 'SHRT'].includes(v) ? -1 : 1),
//   msgFn: () =>
//     'Side is invalid since you cannot do mixed trade for one position in one day.'
// };

const _boxedBookPositionRule = {
  fn: (v, _, ctx) => {
    if (
      ctx &&
      ctx.user &&
      (ctx.user.role === 'Trader' ||
        ['T51', 'T80'].includes(ctx.bookCode) ||
        Trade.isFXTrade(ctx.txnClass))
    )
      return false;
    return (
      ctx &&
      // !isQuantBook(ctx.bookCode) &&
      ((ctx.posn > 0 && ['SHRT', 'COVR'].includes(v)) ||
        (ctx.posn < 0 && ['BUY', 'SELL'].includes(v)))
    );
  },
  msgFn: () =>
    'Side is not valid since this will create boxed position under your book.'
};

const _crossFilledRule = {
  fn: (v, fields, ctx) => {
    const { fundCode, bookCode } = fields;
    if (bookCode !== 'T26') return false;
    if (!isOnShoreFund(fundCode)) return false;

    const { positions, trades } = ctx;
    const oppositeDir = ['BUY', 'COVR'].includes(v) ? 'S' : 'B';
    const oppositeWorkingPos = Object.values(positions).find(
      p =>
        p.fundCode === fundCode &&
        p.bookCode === bookCode &&
        Math.abs(_.get(p.aggUndoneQty, oppositeDir, 0)) > 0
    );

    const oppositeSides = ['BUY', 'COVR'].includes(v)
      ? ['SELL', 'SHRT']
      : ['BUY', 'COVR'];
    const oppositeTrade = trades.find(
      t =>
        isOnShoreFund(t.fundCode) &&
        t.ticker === fields.ticker &&
        oppositeSides.includes(t.side)
    );

    return !_.isNil(oppositeWorkingPos) || oppositeTrade;
  },
  msgFn: () => 'Opposite trades are still working, might cause cross filled.',
  isWarningFn: () => true
};

const validSides = ['BUY', 'SELL', 'SHRT', 'COVR'];
const _sideValueRule = {
  fn: v => !validSides.includes(v),
  msgFn: () =>
    `Side is not valid, the supported value should be [${validSides.join(
      ','
    )}].`
};

const _checkEqtyTicker = {
  fn: (v, fields, ctx) => {
    const { ticker } = fields;
    if (_.isEmpty(ticker) || Trade.isFXTrade(ctx.txnClass)) return false;
    const assetClass = TickerUtils.parseAssetClass(ticker);
    if (!assetClass || assetClass !== TickerUtils.AssetClass.NOT_SPECIFIED)
      return false;
    return TickerUtils.isErrorEqty(ticker);
  },
  msgFn: (_t, ctx) => `The equity ticker is not valid.`
};

const _checkFutureLastTradeableDtRule = {
  fn: (v, fields, ctx) => {
    const { lastTradeableDt } = ctx;
    const { side, ticker } = fields;
    if (
      _.isEmpty(ticker) ||
      _.isEmpty(lastTradeableDt) ||
      Trade.isFXTrade(ctx.txnClass)
    )
      return false;
    const assetClass = TickerUtils.parseAssetClass(ticker);

    if (assetClass == null || !assetClass.endsWith('FUT')) return false;
    if (!['BUY', 'SHRT'].includes(side)) return false;
    const current = getCurrentWorkingDay();
    const lastTradeDt = moment(lastTradeableDt, 'YYYY-MM-DD').subtract(
      7,
      'days'
    );

    return lastTradeDt.diff(current, 'days') <= 0;
  },
  msgFn: (_t, ctx) =>
    `The security last tradable date is: ${ctx.lastTradeableDt}.`,
  isWarningFn: () => true
};

const _checkCloseTradeLotSize = {
  fn: (v, fields, ctx) => {
    if (!ctx) return false;
    if (!['SELL', 'COVR'].includes(fields.side)) return false;

    const { trades } = ctx;
    const posKey = Trade.parsePosKey(fields);

    // 1. must take the other trades into account.
    // 2. must minus prevFilledQuantity if edit GTC order.
    const aggTradeSignedDayQty = _.sum(
      trades
        .filter(t => Trade.parsePosKey(t) === posKey && t.side === fields.side)
        .map(t => Trade.parseSignedDayQty(t))
    );

    return (
      Math.abs(aggTradeSignedDayQty) < Math.abs(ctx.theoreticalPosnForClose) &&
      v % (fields.lotSize || 1) !== 0
    );
  },
  msgFn: fields =>
    `Qty should be rounded to lotsize: ${fields.lotSize || 1} for close trade.`,
  isWarningFn: () => true
};

const _boxedFundPositionRule = {
  fn: (v, fields, ctx) => {
    if (!ctx || !['SHRT', 'BUY'].includes(fields.side)) return false;
    if (isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy))
      return false;
    const { positions } = ctx;
    const { fundCode, bookCode, side } = fields;
    if (_.isEmpty(positions)) return false;
    const filterPos = Object.values(positions).filter(
      r =>
        r.fundCode === fundCode &&
        r.bookCode !== bookCode &&
        !isQuantBook(r.bookCode) &&
        r.theoreticalPosn * Trade.parseTradeSign(side) < 0
    );
    return !_.isEmpty(filterPos);
  },
  msgFn: () => 'Another PM has opposite position on this security.',
  isWarningFn: () => true
};

const _validationRules = {
  bookCode: [
    {
      fn: (v, fields, ctx) => !ctx.usdNav,
      msgFn: () => 'Book code is not valid.'
    }
  ],
  ticker: [
    {
      fn: v => !v,
      msgFn: () => 'Ticker cannot be empty.'
    },
    // {
    //   fn: (v, fields, ctx) => {
    //     const { side } = fields;
    //     return (
    //       ctx &&
    //       ctx.restrictedList.some(
    //         r =>
    //           r.qtyDirection === null ||
    //           (r.qtyDirection === 'LONG' && side === 'BUY') ||
    //           (r.qtyDirection === 'SHORT' && side === 'SHRT')
    //       )
    //     );
    //   },
    //   msgFn: () => 'The ticker is in restricted list.'
    // },
    {
      fn: (v, fields, ctx) => !ctx,
      msgFn: () =>
        'Fail to get security by ticker. (Probably forgot to append exchange code or yellow key?)'
    },
    _checkEqtyTicker,
    _checkFutureLastTradeableDtRule
  ],
  quantity: [
    {
      fn: v => !v,
      msgFn: () => 'Qty cannot be empty or 0.'
    },
    {
      fn: v => v <= 0,
      msgFn: () => 'Qty must be greater than 0.'
    },
    {
      fn: (v, fields) =>
        ['BUY', 'SHRT'].includes(fields.side) &&
        v % (fields.lotSize || 1) !== 0,
      msgFn: fields =>
        `Qty should be rounded to lotsize: ${fields.lotSize ||
          1} for open trade.`,
      isWarningFn: (t, ctx) => ['Trader'].includes(_.get(ctx, 'user.role'))
    },
    _checkCloseTradeLotSize,
    _crossTradeRule,
    _minTradeExposureRule,
    _maxExposureRule,
    _indexFutMaxExpRule,
    _liquidityHardRule,
    _liquiditySoftRule,
    _limitsRule
  ],
  pmReason: [
    {
      fn: v =>
        _.words(v)
          .join(' ')
          .replace(REG_NUM_SPACE, '').length < 3,
      msgFn: () => 'Reason should be at least 3 valid characters.'
    }
  ],
  limitPriceLocal: [
    {
      fn: (v, fields) => fields.orderType === 'LMT' && _.isNaN(parseFloat(v)),
      msgFn: () => 'Limit price should not be empty.'
    },
    {
      fn: (v, fields, ctx) =>
        fields.orderType === 'LMT' &&
        !_.isNaN(parseFloat(v)) &&
        ctx.price &&
        Math.abs((v - ctx.price) / ctx.price) > 0.1,
      msgFn: () =>
        'The limit price differs from the live price by more than 10%.',
      isWarningFn: () => true
    }
  ],
  side: [
    _sideValueRule,
    _boxedBookPositionRule,
    _crossFilledRule,
    _boxedFundPositionRule
  ],
  strategy: [
    {
      fn: v => !v,
      msgFn: () => 'Strategy cannot be empty.'
    },
    {
      fn: v => v && v.length > 15,
      msgFn: () => 'The length of strategy must be within 15 characters.'
    }
  ],
  algoCode: [
    {
      fn: (v, fields) =>
        ['PTF', 'CPTF'].includes(fields.fundCode) &&
        !['TWAP', 'INLINE'].includes(v),
      msgFn: () => 'Only INLINE and TWAP algos are supported for paper trades.'
    },
    {
      fn: (v, fields, ctx) =>
        !(isQuantBook(ctx.bookCode) || isQuantStrategyBook(ctx.bookStrategy)) &&
        fields.algoCode === 'DMA' &&
        !_.isEmpty(fields.ticker) &&
        TickerUtils.AssetClass.EQTY ===
          TickerUtils.parseAssetClass(fields.ticker) &&
        ['CH', 'C1', 'C2'].includes(TickerUtils.parseExch(fields.ticker)),
      msgFn: () => 'For CH equity market, cannot use DMA algo.'
    }
  ],
  settleDate: [
    {
      fn: (v, fields) => Trade.isFXTrade(fields.txnClass) && !v,
      msgFn: () => 'SettleDate cannot be empty.'
    }
  ],
  pmRemark: [
    {
      fn: (v, fields) =>
        ['PTF', 'CPTF'].includes(fields.fundCode) &&
        'TWAP' === fields.algoCode &&
        !containsTimeRange(v),
      msgFn: () =>
        'For TWAP algo, time range must be specified in remark (format: HH:mm-HH:mm).'
    }
  ]
};

const _validationIPOTradesRules = {
  fundBook: [
    {
      fn: v => !v,
      msgFn: () => 'Fund-Book cannot be empty.'
    }
  ],
  ticker: [
    {
      fn: v => !v,
      msgFn: () => 'Ticker cannot be empty.'
    }
  ],
  qtyUsd: [
    {
      fn: v => !v,
      msgFn: () => 'Trade value cannot be empty or 0.'
    },
    {
      fn: v => v <= 0,
      msgFn: () => 'Trade value must be greater than 0.'
    }
  ],
  pmReason: [
    {
      fn: v =>
        _.words(v)
          .join(' ')
          .replace(REG_NUM_SPACE, '').length < 3,
      msgFn: () => 'Reason should be at least 3 valid characters.'
    }
  ],
  limitPriceLocal: [
    {
      fn: (v, fields) => fields['orderType'] === 'LMT' && !v,
      msgFn: () => 'Lmt Price  cannot be empty.'
    }
  ]
};

const _buildValidationCtx = (
  trade,
  security,
  riskInfoMap,
  fundBooks,
  user,
  trades
) => {
  const { fundCode, bookCode, strategy, limitPriceLocal } = trade;
  const riskKey = `${fundCode}-${bookCode}`;
  const masterKey = _.get(fundBooks, [riskKey, 'masterFundBook']);
  const risk =
    (riskInfoMap || {})[riskKey] || (riskInfoMap || {})[masterKey] || {};
  if (!security) {
    return null;
  }

  const {
    ticker,
    avgVolume,
    price,
    priceMultiplier,
    usdFxRate,
    positions,
    limits,
    lastTradeableDt,
    txnClass
  } = security;

  // In validation, dont use undl price for option as did in calculation.
  const usdValuePerShare =
    (price || limitPriceLocal || 0) * priceMultiplier * usdFxRate;

  const { usdNav, strategy: bookStrategy } = risk;
  const posKey = `${fundCode}-${bookCode}-${strategy}`;
  const { theoreticalPosn = 0, theoreticalPosnForClose = 0, posn = 0 } =
    security.positions[posKey] || {};

  const aggTheoreticalPosn = Object.keys(security.positions)
    .filter(k => k.startsWith(riskKey))
    .reduce((acc, k) => acc + security.positions[k].theoreticalPosn, 0);

  return {
    fundCode,
    bookCode,
    ticker,
    avgVolume,
    usdNav,
    usdValuePerShare,

    posn,
    theoreticalPosn,
    theoreticalPosnForClose,

    aggTheoreticalPosn,
    price,
    user,

    trades: _.isEmpty(trades) ? [trade] : trades,
    limits,
    positions,

    lastTradeableDt,
    txnClass,
    bookStrategy
  };
};

const TradeValidator = {
  validate: (
    trade,
    security,
    riskInfoMap,
    name = '',
    fundBooks,
    user,
    trades
  ) => {
    const ctx = _buildValidationCtx(
      trade,
      security,
      riskInfoMap,
      fundBooks,
      user,
      trades
    );

    const fieldErrors = {};

    const names = name ? new Set([name]) : new Set();
    if (['qtyUsd', 'qtyPct', 'side', 'strategy'].includes(name))
      names.add('quantity');
    if (names.has('quantity')) names.add('side');
    if (names.has('side')) names.add('ticker');
    if (names.has('orderType')) names.add('limitPriceLocal');
    if (names.has('fundCode')) names.add('algoCode');
    if (names.has('algoCode')) names.add('pmRemark');

    Object.entries(_validationRules)
      .filter(([k]) => names.size === 0 || names.has(k))
      .forEach(([k, vs]) => {
        const targetValue = trade[k];
        const matchedRule = vs.find(v => {
          return v.fn(targetValue, trade, ctx);
        });

        fieldErrors[k] = matchedRule
          ? {
              msg: matchedRule.msgFn(trade, ctx),
              isWarning:
                'isWarningFn' in matchedRule
                  ? matchedRule.isWarningFn(trade, ctx)
                  : false
            }
          : null;
      });

    return fieldErrors;
  },
  validateIPOTrades: (trade, name = '') => {
    const fieldErrors = {};

    const names = name ? new Set([name]) : new Set();

    Object.entries(_validationIPOTradesRules)
      .filter(([k]) => names.size === 0 || names.has(k))
      .forEach(([k, vs]) => {
        const targetValue = trade[k];
        const matchedRule = vs.find(v => {
          return v.fn(targetValue, trade, null);
        });

        fieldErrors[k] = matchedRule
          ? {
              msg: matchedRule.msgFn(trade, null),
              isWarning:
                'isWarningFn' in matchedRule
                  ? matchedRule.isWarningFn(trade, null)
                  : false
            }
          : null;
      });

    return fieldErrors;
  }
};

export default TradeValidator;
